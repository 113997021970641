<div
  class="search-bar-row"
  fxFlex
  fxLayout="row"
  fxLayoutAlign="space-between start"
>
  <!--Logo-->
  <div class="logo animate__animated animate__fadeIn" (click)="goBack()">
    <img src="../../assets/logo.png" />
  </div>
  <!--Logo-->

  <div class="project-info" *ngIf="objectData">
    {{ objectData.project
    }}<span *ngIf="objectData.palletId"
      >&nbsp;&gt; <a>{{ objectData.palletId }}</a></span
    >&nbsp; &gt; {{ objectData.batchID }}
  </div>

  <!--Search box-->
  <div
    *ngIf="showSearch"
    fxFlex="40"
    fxLayout="row"
    fxLayoutAlign="center start"
  >
    <mat-form-field class="search-bar" appearance="outline" fxFlex="90">
      <input
        AutofocusDirective
        [(ngModel)]="searchWord"
        [disabled]="!isUserAllowedToSearch"
        autocomplete="off"
        matInput
        type="text"
        (keydown.enter)="searchObject()"
        placeholder="{{ 'Search batch registration' | translate }}"
      />
      <mat-hint>{{ searchHint }}</mat-hint>
    </mat-form-field>
    <button
      class="search-button"
      mat-fab
      color="primary"
      aria-label="Search"
      (click)="searchObject()"
    >
      <mat-icon>search</mat-icon>
    </button>
  </div>
  <!--Search box-->

  <!--Log out button-->
  <button
    class="log_out animate__animated animate__fadeIn"
    color="warn"
    mat-raised-button
    (click)="logOut()"
    *ngIf="showLogout"
  >
    <span class="material-icons-two-tone"> logout </span>
    {{ "Log Out" | translate }}
  </button>
  <!--Log out button-->
</div>
