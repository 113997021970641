import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {
  fromEvent,
  interval,
  merge,
  Observable,
  skipWhile,
  Subscription,
  switchMap,
  take,
} from 'rxjs';
import { DashboardModalComponent } from '../dashboard-modal/dashboard-modal.component';
import { AtelierViews } from '../enums/AtelierViews';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent implements OnInit, OnDestroy {
  searchWord: string;
  searchHint: string = '';
  breakpoint: number;

  inactivityTimerEvent: Array<any>[] = [
    [document, 'click'],
    [document, 'wheel'],
    [document, 'scroll'],
    [document, 'mousemove'],
    [document, 'keyup'],
    [window, 'resize'],
    [window, 'scroll'],
    [window, 'mousemove'],
  ];

  mergedObservable: Observable<any>;
  observable: Observable<any>;
  inactivityTime: number = 30;
  timeLapsedSinceInactivity: number = 0;
  timerSubscription: Subscription;

  public get Views(): typeof AtelierViews {
    return AtelierViews;
  }

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private authService: AuthService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.breakpoint = window.innerWidth <= 720 ? 2 : 4;

    let observableArray$: Observable<any>[] = [];

    this.inactivityTimerEvent.forEach((x) => {
      observableArray$.push(fromEvent(x[0], x[1]));
    });

    this.mergedObservable = merge(...observableArray$);

    this.observable = this.mergedObservable.pipe(
      switchMap((ev) => interval(1000).pipe(take(this.inactivityTime))),
      skipWhile((x) => {
        this.timeLapsedSinceInactivity = x;
        return x != this.inactivityTime - 1;
      })
    );

    //this.timerSubscription = this.observable.subscribe(() => this.logOut());
  }

  ngOnDestroy(): void {
    //this.timerSubscription.unsubscribe();
  }

  onResize(event: any) {
    this.breakpoint = event.target.innerWidth <= 720 ? 2 : 4;
  }

  async searchObject() {
    if (this.searchWord == null) this.searchHint = 'Enter a valid number';
    else
      this.router.navigate([
        AtelierViews.REGISTRATION.split('/')[0],
        this.searchWord,
      ]);
  }

  navigate(route: string) {
    this.router.navigate([route]);
  }

  openDashboard() {
    if (this.authService.userCanAccessToView(AtelierViews.DASHBOARD)) {
      const dialogRef = this.dialog.open(DashboardModalComponent, {
        width: '100%',
        data: {},
      });

      dialogRef.afterClosed().subscribe();
    }
  }

  userCanAccess(sectionName: keyof typeof AtelierViews) {
    return this.authService.userCanAccessToView(AtelierViews[sectionName]);
  }

  logOut() {
    if (this.cookieService.check('token')) {
      this.cookieService.delete('token', '/');
      var location = window.location.href;
      var logOutWindow = window.open(
        'https://login.input4you.be/Login/Logout',
        '_blank'
      );

      setTimeout(() => {
        logOutWindow!.close();
        window.location.href = location;
      }, 100);
    }
  }
}
