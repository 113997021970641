<div mat-dialog-title>
    <h1>{{'Select project for this folder' | translate}}</h1>     
    <button mat-raised-button color="warn" (click)="onNoClick()">{{'Cancel' | translate}}</button>
</div>
<div mat-dialog-content>
    <div class="project-grid">
        <button class="project-button" id="{{project}}" (click)="selectProject(project)" mat-raised-button color="primary" *ngFor="let project of data.list">
            {{project}}
        </button>
    </div>
</div>