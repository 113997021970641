<div class="window" fxLayout="column" fxLayoutGap="20px" fxFlexFill>
  <app-header></app-header>
  <div class="main-container">
    <h1 class="animate__animated animate__fadeIn">
      {{ "Triage" | translate }}
    </h1>

    <form [formGroup]="triageForm">
      <div class="mat-headline row animate__animated animate__fadeIn">
        <mat-form-field appearance="outline">
          <mat-label>{{ "Project" | translate }}</mat-label>
          <input
            formControlName="project"
            matInput
            autocomplete="off"
            (click)="selectProject()"
          />
        </mat-form-field>
      </div>

      <div class="mat-headline row animate__animated animate__fadeIn">
        <mat-form-field appearance="outline">
          <mat-label>{{ "Spent time (min)" | translate }}</mat-label>
          <input
            formControlName="time"
            autocomplete="off"
            matInput
            type="text"
            pattern="\d*"
          />
        </mat-form-field>
      </div>
    </form>

    <div class="row buttons animate__animated animate__fadeIn">
      <button mat-raised-button color="warn" (click)="cancelButton()">
        {{ "Cancel" | translate }}
      </button>
      <button mat-raised-button class="done-button" (click)="onSave()">
        {{ "Done" | translate }}
      </button>
    </div>
  </div>
  <div class="spacer"></div>
  <app-footer></app-footer>
</div>
