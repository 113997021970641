<table mat-table [dataSource]="dataSource">
  <!--Process name-->
  <ng-container matColumnDef="Process">
    <th mat-header-cell *matHeaderCellDef>Process Name</th>
    <td mat-cell *matCellDef="let element">{{ element.Process }}</td>
  </ng-container>

  <!--Operator-->
  <ng-container matColumnDef="Operator">
    <th mat-header-cell *matHeaderCellDef>Operator</th>
    <td mat-cell *matCellDef="let element">{{ element.Operator }}</td>
  </ng-container>

  <!--Start Time-->
  <ng-container matColumnDef="StartTime">
    <th mat-header-cell *matHeaderCellDef>Start time</th>
    <td mat-cell *matCellDef="let element">{{ element.StartTime }}</td>
  </ng-container>

  <!--End Time-->
  <ng-container matColumnDef="EndTime">
    <th mat-header-cell *matHeaderCellDef>End time</th>
    <td mat-cell *matCellDef="let element">{{ element.EndTime }}</td>
  </ng-container>

  <!--End Time-->
  <ng-container matColumnDef="TimeTaken">
    <th mat-header-cell *matHeaderCellDef>Time taken (s)</th>
    <td mat-cell *matCellDef="let element">{{ element.TimeTaken }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
