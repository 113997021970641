import { Directive } from '@angular/core';
import { MatInput } from '@angular/material/input';

@Directive({
  selector: '[AutofocusDirective]',
})
export class AutofocusDirectiveDirective {
  constructor(private matInput: MatInput) {}

  ngOnInit() {
    setTimeout(() => this.matInput.focus());
  }
}
