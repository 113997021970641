import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { AtelierViews } from '../enums/AtelierViews';

@Injectable({
  providedIn: 'root',
})
export class UserHasPermissionsGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      this.authService.isCurrentUserDevelopmentTeamMember() ||
      this.authService.isCurrentUserQualityControllMember() ||
      this.authService.isAtelierUser()
    )
      return true;

    // If we split the URL we can use child views also. E.g. /BATCHES/DKV123
    const targetUrl = state.url.split('/')[1];

    // Get the user allowed views
    const userAllowedViews = this.authService
      .getUserViewsPermissions()
      .map((p) => p.toUpperCase());

    // If the user is not allowed, redirect to main view
    if (!userAllowedViews.includes(targetUrl))
      this.router.navigate([AtelierViews.START]);

    return true;
  }
}
