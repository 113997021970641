import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { catchError, EMPTY, map, of, shareReplay, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Batch } from '../batch/Interfaces/Batch';
import { BatchStatus } from '../batch/Interfaces/BatchStatus';
import { BoxRange } from '../batch/Interfaces/BoxRange';
import { Others, Reception } from '../grid-table/interfaces/grid.interface';
import { Project } from '../interfaces/Project.interface';
import { ProgressModalComponent } from '../progress-modal/progress-modal.component';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  objectData: any = null;
  projectsList: Array<Project> = [];
  bpost1Projects: Array<Project> = [];
  regLetterProjects: Array<Project> = [];
  privateProjects: Array<Project> = [];
  otherProjects: Array<any> = [];
  triageProjects: Array<string> = [];
  snackbarDuration = 10000;

  projectsApiRequest = this.http
    .get<Project[]>(`${environment.atelierController}api/Projects`)
    .pipe(
      map((projects) => {
        if (
          this.authService.isCurrentUserDevelopmentTeamMember() ||
          this.authService.isCurrentUserQualityControllMember() ||
          this.authService.isAtelierUser()
        )
          return projects;
        return projects.filter((p) => {
          const parsedName = p.name.replace(' ', '');
          return this.authService.UserProjects.some((x) =>
            x.includes(parsedName)
          );
        });
      }),
      shareReplay()
    );

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private authService: AuthService
  ) {}

  searchByPalletBoxBatch(number: string) {
    const url = `${environment.atelierController}api/Batches/${number}`;
    return this.http.get<Batch>(url);
  }

  async getSavedObject(number: string) {
    if (this.objectData != null) return this.objectData;
    else return await this.searchByPalletBoxBatch(number);
  }

  getAllProjects() {
    return this.projectsApiRequest.pipe(
      map((projects) => {
        projects = this.orderList(projects);
        this.processProjectsLists(projects);
        return projects;
      }),
      catchError((error) => {
        console.error('[ApiService] getAllProjects: \n', error);
        return of([] as Project[]);
      })
    );
  }

  processProjectsLists(allProjects: Array<Project>) {
    allProjects.forEach((project) => {
      if (project.receptionType.find((type) => type.name == 'Bpost1')) {
        this.bpost1Projects.push(project);
      }
      if (project.receptionType.find((type) => type.name == 'Private')) {
        this.privateProjects.push(project);
      }
    });
  }

  orderList(list: Array<Project>) {
    var orderedList = list.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    return orderedList;
  }

  async getRegLetterProjects(): Promise<Project[]> {
    if (this.regLetterProjects.length > 0) return this.regLetterProjects;
    else {
      const url = environment.atelierController + 'api/Projects/Type/bpost2';
      const dialogRef = this.dialog.open(ProgressModalComponent);
      return await this.http
        .get(url)
        .toPromise()
        .then((data: any) => {
          this.regLetterProjects = data;
          dialogRef.close();
          return data;
        })
        .catch((error) => {
          console.error('[ApiService] getRegLetterProjects: \n', error);
          dialogRef.close();
          return [];
        });
    }
  }

  /**
   * Get the projects from the API, filter them to only include projects that have a reception type of
   * 'Bpost1', and then share the result with any other subscribers.
   * @returns The projectsApiRequest is being returned.
   */
  getBpostProjects() {
    return this.projectsApiRequest.pipe(
      map((projects) =>
        projects.filter((p) => p.receptionType.some((t) => t.name == 'Bpost1'))
      ),
      shareReplay()
    );
  }

  /**
   * It gets all projects from the server, filters them to only include projects with a reception type
   * of 'Private', and then returns the result.
   * @returns An Observable of an array of Project objects.
   */
  getPrivateProjects() {
    return this.projectsApiRequest.pipe(
      map((projects) =>
        projects.filter((p) => p.receptionType.some((t) => t.name == 'Private'))
      ),
      tap((x) => console.log(x)),
      shareReplay()
    );
  }

  getOtherTypes() {
    if (this.otherProjects.length > 0) return of(this.otherProjects);
    else {
      const dialogRef = this.dialog.open(ProgressModalComponent);
      const url = environment.atelierController + 'api/OtherTypes';

      return this.http.get<any[]>(url).pipe(
        tap((response) => {
          this.otherProjects = response as Array<any>;
          dialogRef.close();
        }),
        catchError((err) => {
          dialogRef.close();
          console.error('[ApiService] getOtherTypes: \n', err);
          return EMPTY;
        })
      );
    }
  }

  async getReceptionsByDate(startDate: Date, endDate: Date) {
    const dialogRef = this.dialog.open(ProgressModalComponent);
    const formatedStartDate: string = moment(startDate).format('MM/DD/YYYY');
    const formatedEndDate: string = moment(endDate).format('MM/DD/YYYY');
    const url = `${environment.atelierController}api/Receptions/ByDate?start=${formatedStartDate}&end=${formatedEndDate}`;

    return await this.http
      .get(url)
      .toPromise()
      .then((data: any) => {
        dialogRef.close();
        return data;
      })
      .catch((error) => {
        dialogRef.close();
        console.error('[ApiService] getReceptions: \n', error);
        return [];
      });
  }

  async getOthersByDate(startDate: Date, endDate: Date) {
    const dialogRef = this.dialog.open(ProgressModalComponent);
    const formatedStartDate: string = moment(startDate).format('MM/DD/YYYY');
    const formatedEndDate: string = moment(endDate).format('MM/DD/YYYY');
    const url = `${environment.atelierController}api/Others/ByDate?start=${formatedStartDate}&end=${formatedEndDate}`;

    return await this.http
      .get<Others[]>(url)
      .toPromise()
      .then((data: any) => {
        dialogRef.close();
        return data;
      })
      .catch((error) => {
        dialogRef.close();
        console.error('[ApiService] getOthers: \n', error);
        return [];
      });
  }

  async getRegLettersByDate(startDate: Date, endDate: Date) {
    const dialogRef = this.dialog.open(ProgressModalComponent);
    const formatedStartDate: string = moment(startDate).format('MM/DD/YYYY');
    const formatedEndDate: string = moment(endDate).format('MM/DD/YYYY');
    const url = `${environment.atelierController}api/RegLetters/ByDate?start=${formatedStartDate}&end=${formatedEndDate}`;

    return await this.http
      .get(url)
      .toPromise()
      .then((data: any) => {
        dialogRef.close();
        return data;
      })
      .catch((error) => {
        dialogRef.close();
        console.error('[ApiService] getRegLetters: \n', error);
        return [];
      });
  }

  /**
   * I'm going to make a GET request to the url, and then I'm going to return the result of that
   * request, and I'm going to share the result of that request with any other subscribers that come
   * along later.
   * @returns An Observable
   */
  getTodayBpost1() {
    const url = `${environment.atelierController}api/Receptions/GetTodayBpostReception`;

    return this.http.get<Reception>(url).pipe(shareReplay());
  }

  async updateBpost1Projects(projects: any) {
    const dialogRef = this.dialog.open(ProgressModalComponent);
    const url =
      environment.atelierController + 'api/Receptions/UpdateBpostProjects';

    return await this.http
      .post(url, projects, { observe: 'response' })
      .toPromise()
      .then((data: any) => {
        dialogRef.close();
        return data;
      })
      .catch((error) => {
        dialogRef.close();
        console.error('[ApiService] updateBpost1Projects: \n', error);
        return error;
      });
  }

  sendBatch(json: any, numberOfBatches: number) {
    const url = environment.atelierController + 'api/Batches';
    const data = JSON.stringify({
      json,
      numberOfBatches,
    });

    return this.http.post<Batch>(url, data, {
      headers: { 'Content-Type': 'application/json' },
      observe: 'response',
    });
  }

  async sendRegLetter(json: any) {
    const url = environment.atelierController + 'api/RegLetters';

    return await this.http
      .post(url, json, { observe: 'response' })
      .toPromise()
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error('[ApiService] sendRegLetter: \n', error);
        return error;
      });
  }

  async sendReception(json: any) {
    const url = environment.atelierController + 'api/Receptions';

    return await this.http
      .post(url, json, { observe: 'response' })
      .toPromise()
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error('[ApiService] sendRegLetter: \n', error);
        return error;
      });
  }

  sendOthers(json: any) {
    const url = environment.atelierController + 'api/Others';

    return this.http
      .post(url, json, {
        observe: 'response',
      })
      .pipe(
        catchError((error) => {
          console.error('[ApiService] sendOthers: \n', error);
          return throwError(() => {});
        })
      );
  }

  deleteBatch(id: string) {
    const url = `${environment.atelierController}api/Batches/${id}`;
    return this.http.delete<Batch>(url);
  }

  async getBoxRangesForProject(projectName: string) {
    const url = `${environment.atelierController}api/BoxRanges/project/${projectName}`;

    return await this.http
      .get(url)
      .toPromise()
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log('[ApiService] getBoxRangesForProject: \n', error);
        return error;
      });
  }

  logNewBatch(batch: Batch) {
    const url = `${environment.atelierController}api/BatchStatus`;
    return this.http.post<BatchStatus>(url, batch, { observe: 'response' });
  }

  getBatchStatusForBatch(batchID: string) {
    const url = `${environment.atelierController}api/BatchStatus/${batchID}`;
    return this.http.get<BatchStatus>(url);
  }

  moveBatchStatusToNextStep(batchStatus: BatchStatus) {
    const url = `${environment.atelierController}api/BatchStatus/${batchStatus.batchStatusId}`;
    return this.http.put<BatchStatus>(url, batchStatus);
  }

  updateBatch(batch: Batch) {
    const url = `${environment.atelierController}api/Batches/${batch.batchNumber}`;
    return this.http.put<Batch>(url, batch);
  }

  updateBoxRange(boxRange: BoxRange) {
    const url = `${environment.atelierController}api/BoxRanges/${boxRange.id}`;
    return this.http.put<BoxRange>(url, JSON.stringify(boxRange), {
      headers: { 'Content-Type': 'application/json' },
      observe: 'response',
    });
  }

  getTriageProjects() {
    const url = `${environment.atelierController}api/Triages/projects`;
    return this.http.get<string[]>(url);
  }

  postTriage(triage: any) {
    const url = `${environment.atelierController}api/Triages`;
    return this.http.post(url, triage, { observe: 'response' });
  }
}
