import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { catchError, EMPTY } from 'rxjs';
import { SelectionModalComponent } from '../selection-modal/selection-modal.component';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-triage',
  templateUrl: './triage.component.html',
  styleUrls: ['../styles/common-styles.scss', './triage.component.scss'],
})
export class TriageComponent implements OnInit {
  triageProjects: string[] = [];

  triageForm = new FormGroup({
    project: new FormControl('', [Validators.required]),
    time: new FormControl('', [Validators.required, Validators.min(0)]),
  });

  constructor(
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  /**
   * We're calling the `getTriageProjects()` function from the `apiService` and then assigning the
   * result to the `triageProjects` variable
   */
  ngOnInit(): void {
    this.apiService.getTriageProjects().subscribe((projects) => {
      this.triageProjects = projects.sort();
      this.selectProject();
    });
  }

  /**
   * It opens a modal dialog, and when the user closes the dialog, it sets the value of the form
   * control to the value the user selected in the modal
   */
  selectProject() {
    const dialogRef = this.dialog.open(SelectionModalComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: { list: this.triageProjects },
    });
    dialogRef
      .afterClosed()
      .subscribe((selectedProject) =>
        this.triageForm.get('project').setValue(selectedProject)
      );
  }

  /**
   * We're getting the form values, adding the username to the data object, and then posting the data
   * to the API
   */
  onSave() {
    const data = {
      username: this.authService.getUsername(),
      time: this.triageForm.get('time').value,
      project: {
        name: this.triageForm.get('project').value,
      },
      creationDate: moment().utc().format().toString(),
    };

    console.log(data);

    this.apiService
      .postTriage(data)
      .pipe(
        catchError(() => {
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.cancelButton();
        this._snackBar.open(
          `Triage for ${data.project.name} 
          created at ${moment().format('DD/MM/YYYY hh:mm')}`,
          'Ok',
          {
            duration: this.apiService.snackbarDuration,
          }
        );
      });
  }

  /**
   * The cancelButton() function navigates the user back to the home page
   */
  cancelButton() {
    this.router.navigate(['/']);
  }
}
