import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { catchError, EMPTY, map, switchMap } from 'rxjs';
import { Batch } from '../batch/Interfaces/Batch';
import { BatchStatus } from '../batch/Interfaces/BatchStatus';
import { DeletionConfirmationComponent } from '../deletion-confirmation/deletion-confirmation.component';
import { SelectionModalComponent } from '../selection-modal/selection-modal.component';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { ActionsTableElement } from './table/interfaces/table.interface';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class ActionsComponent implements OnInit {
  searchNumber: string;
  searchMessage = '';
  batchData: Batch;
  batchStatus: BatchStatus;
  nextStep: string;
  tableInfo: ActionsTableElement[] = [];
  dialogDefaultConfig: MatDialogConfig = {
    maxWidth: '100vw',
    maxHeight: '100vh',
    height: '100%',
    width: '100%',
    data: {},
  };

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.lodaData();
  }

  lodaData() {
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          this.searchNumber = params.get('searchNumber');
          return this.apiService.searchByPalletBoxBatch(this.searchNumber).pipe(
            catchError(() => {
              this.createNewBatch();
              return EMPTY;
            })
          );
        }),
        switchMap((batchInfo) => {
          this.batchData = batchInfo as Batch;
          this.nextStep = this.getNextStatus(this.batchData.status);

          const batchStatus = this.batchData.status;
          return this.apiService.getBatchStatusForBatch(this.batchData.batchID);
        })
      )
      .subscribe((batchStatus) => {
        this.batchStatus = batchStatus;
        this.parseBatchStatusToTableFormat();
      });
  }

  get batchCurrentStatus(): string {
    return this.batchData.status;
  }

  createNewBatch() {
    this.apiService
      .getAllProjects()
      .pipe(
        map((projects) =>
          projects.filter((project) =>
            project.receptionType.some((type) => type.id == 1 || type.id == 3)
          )
        ),
        map((projects) =>
          projects.map((project) => {
            return `${project.name} ${project.area}`;
          })
        ),
        map((projects) => projects.sort())
      )
      .subscribe((projects) => {
        const config = { ...this.dialogDefaultConfig };

        config.data = { list: projects };

        const dialogRef = this.dialog.open(SelectionModalComponent, config);

        dialogRef.afterClosed().subscribe((result: string) => {
          // If the project is Ethias we have to consider also the area
          let selectedProject = result;
          let selectedProjectArea = '';

          if (result.includes('ETHIAS')) {
            [selectedProject, selectedProjectArea] = result.split(' '); // Split project name and area
          }

          let batch: Batch = {
            batchNumber: '0',
            project: selectedProject,
            area: selectedProjectArea,
            batchID: this.searchNumber,
            boxRange: null,
            creationDate: moment().utc().format().toString(),
            username: this.authService.getUsername(),
            type: 'batch',
            status: 'RECEIVED',
          };
          this.apiService
            .sendBatch(batch, 1)
            .pipe(
              catchError(() => {
                this.snackbar.open(
                  'There was a problem when creating the batch',
                  'OK',
                  {
                    duration: 3000,
                  }
                );
                return EMPTY;
              }),
              switchMap(() => this.apiService.logNewBatch(batch))
            )
            .subscribe(() => this.lodaData());
        });
      });
  }

  deleteBatch() {
    const dialogRef = this.dialog.open(DeletionConfirmationComponent, {
      data: this.batchData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == true)
        this.apiService
          .deleteBatch(this.batchData.batchNumber)
          .subscribe((deletedBatch) => {
            this.snackbar.open(
              `Batch: ${deletedBatch.batchID} was deleted`,
              'OK',
              { duration: 4000 }
            );
            setTimeout(() => {
              this.router.navigate(['/']);
            }, 4000);
          });
    });
  }

  printButton() {
    print();
  }

  cancelButton() {
    this.router.navigate(['/']);
  }

  confirmNextStatus() {
    this.batchData.status = this.getNextStatus(this.batchData.status);

    if (this.batchData.status === 'START_PREPARATION') {
      this.batchStatus.preparationUser = this.authService.getUsername();
      this.batchStatus.preparationStartTime = moment().utc().format();
    }

    if (this.batchData.status === 'PREPARED') {
      this.batchStatus.preparationEndTime = moment().utc().format();
      this.batchStatus.preparedUser = this.authService.getUsername();
    }

    if (this.batchData.status === 'SCANNED') {
      this.batchStatus.scannedUser = this.authService.getUsername();
      this.batchStatus.scannedTime = moment().utc().format();
    }

    this.batchData.status = this.nextStep;

    this.apiService
      .updateBatch(this.batchData)
      .pipe(
        switchMap(() =>
          this.apiService.moveBatchStatusToNextStep(this.batchStatus)
        )
      )
      .subscribe(() => this.router.navigate(['/']));
  }

  getNextStatus(status: String) {
    switch (status) {
      case 'RECEIVED':
        return 'START_PREPARATION';
      case 'START_PREPARATION':
        return 'PREPARED';
      case 'PREPARED':
        return 'SCANNED';
      default:
        return '';
    }
  }

  parseBatchStatusToTableFormat() {
    const receivedTimeParsed = moment
      .utc(this.batchStatus.receivedTime)
      .local();
    const preparationStartTimeParsed = moment
      .utc(this.batchStatus.preparationStartTime)
      .local();
    const preparationEndTimeParsed = moment
      .utc(this.batchStatus.preparationEndTime)
      .local();
    const scannedTimeParsed = moment.utc(this.batchStatus.scannedTime).local();
    const newTableInfo = [];

    // RECEIVED
    const received: ActionsTableElement = {
      Process: 'RECEIVED',
      Operator: this.batchStatus.receivedUser,
      StartTime: receivedTimeParsed.format('YYYY-MM-DD HH:mm:ss'),
      EndTime: receivedTimeParsed.format('YYYY-MM-DD HH:mm:ss'),
      TimeTaken: 0,
    };

    newTableInfo.push(received);

    // START_PREPARATION
    if (this.batchStatus.preparationStartTime) {
      const preparation: ActionsTableElement = {
        Process: 'START PREPARATION',
        Operator: this.batchStatus.preparationUser,
        StartTime: preparationStartTimeParsed.format('YYYY-MM-DD HH:mm:ss'),
        EndTime: preparationEndTimeParsed.isValid()
          ? preparationEndTimeParsed.format('YYYY-MM-DD HH:mm:ss')
          : '-',
        TimeTaken: preparationEndTimeParsed.isValid()
          ? moment
              .duration(
                preparationEndTimeParsed.diff(preparationStartTimeParsed)
              )
              .asSeconds()
          : 0,
      };

      newTableInfo.push(preparation);
    }

    // PREPARED
    if (this.batchStatus.preparedUser) {
      const prepared: ActionsTableElement = {
        Process: 'PREPARED',
        Operator: this.batchStatus.preparedUser,
        StartTime: preparationEndTimeParsed.format('YYYY-MM-DD HH:mm:ss'),
        EndTime: scannedTimeParsed.isValid()
          ? scannedTimeParsed.format('YYYY-MM-DD HH:mm:ss')
          : '-',
        TimeTaken: scannedTimeParsed.isValid()
          ? moment
              .duration(scannedTimeParsed.diff(preparationEndTimeParsed))
              .asSeconds()
          : 0,
      };

      newTableInfo.push(prepared);
    }

    // SCANNED
    if (this.batchStatus.scannedUser) {
      const scanned: ActionsTableElement = {
        Process: 'SCANNED',
        Operator: this.batchStatus.scannedUser,
        StartTime: scannedTimeParsed.format('YYYY-MM-DD HH:mm:ss'),
        EndTime: scannedTimeParsed.format('YYYY-MM-DD HH:mm:ss'),
        TimeTaken: 0,
      };
      newTableInfo.push(scanned);
    }

    this.tableInfo = [...newTableInfo.reverse()];
  }
}
