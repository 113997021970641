import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as JsBarcode from 'jsbarcode';

@Component({
  selector: 'app-print-view',
  templateUrl: './print-view.component.html',
  styleUrls: ['./print-view.component.scss'],
})
export class PrintViewComponent implements OnInit, AfterViewInit {
  barcodeNumber: number;
  project: string = '';
  totalBatches: number;
  creationDate: string | Date;
  username: String;
  auxArray: string[] = [];

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.barcodeNumber = parseInt(params['batchId']);
      this.project = params['project'];
      this.creationDate = params['date'];
      this.totalBatches = parseInt(params['totalBatches']);
      this.username = params['user'];
      this.generateBarcodeList();
    });
  }

  generateBarcodeList() {
    // Add first value
    this.auxArray.push(this.barcodeNumber.toString());
    // Create next totalBatches values
    for (let i = 0; i < this.totalBatches - 1; i++) {
      this.barcodeNumber++;
      this.auxArray.push(this.barcodeNumber.toString());
    }
  }

  ngAfterViewInit(): void {
    for (let index = 0; index < this.auxArray.length; index++) {
      JsBarcode(`#barcode_${index}`, this.auxArray[index].toString());
    }
    window.print();
    this.router.navigate(['/']);
  }
}
