import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AdUser } from '../interfaces/ADUser.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private aduser: AdUser = null;

  private userPermissions: string[] = [];

  public userProjects: string[] = [];

  constructor(private http: HttpClient) {}

  /**
   * This function takes a string as an argument and sets the window.location.href to a url that is a
   * concatenation of the environment.loginUrl and the string argument.
   * @param {string} name - string - the name of the application you want to login to
   */
  login(name: string) {
    const url: string = environment.loginUrl + 'LoginPin?redirectUri=' + name;
    window.location.href = url;
  }

  /**
   * "This function is called when the user logs in, and it returns a promise that resolves to the
   * user's data."
   *
   * The function is called from the login component, and the user's data is stored in the service
   * @param {string} token - string - the token that was generated by the login service
   * @returns The data object is being returned.
   */
  async authorize(token: string): Promise<any> {
    const url: string = environment.loginUrl + 'Authorize?token=' + token;

    return await this.http
      .post(url, '')
      .toPromise()
      .then((data: any) => {
        this.aduser = data.aduser;
        this.parseUserPermissions();
        this.parseUserProjects();
        return data;
      })
      .catch((error) => {
        console.error(
          '[AuthService] authorize(token) - ERROR obtaining authorize of token \n',
          error
        );
      });
  }

  /**
   * It takes an array of objects, filters it based on a condition, and then maps the result to a new
   * array.
   */
  private parseUserPermissions() {
    this.userPermissions = this.aduser.PermisionGroups.filter((p) =>
      p.GroupName.includes('Atelier')
    ).map((p) => p.GroupName.replace('Atelier', '').toUpperCase());
  }

  /**
   * Returns the current user projects name list
   * @param user - the user object
   * @returns An array of strings.
   */
  private parseUserProjects() {
    const firstList = this.aduser.PermisionGroups.filter(
      (group) => group.GroupName == 'Users'
    ).map((group) => group.SAMName.replace('Users', ''));

    const secondList = this.aduser.PermisionGroups.filter(
      (group) =>
        group.GroupName == group.SAMName && group.GroupName.includes('Users')
    ).map((group) => group.SAMName.replace('Users', ''));

    firstList.push(...secondList);

    this.userProjects = [...new Set(firstList)];
  }

  /**
   * If the user is authenticated, return the token, otherwise return null.
   * @returns The token is being returned.
   */
  public get AuthToken() {
    if (this.isAuthenticated()) {
      return this.aduser.Token;
    } else return null;
  }

  public get UserProjects() {
    return this.userProjects;
  }

  /**
   * If the user is authenticated, return the user's SAMName, otherwise return null
   * @returns The username of the user that is currently logged in.
   */
  getUsername() {
    if (this.isAuthenticated()) {
      return this.aduser.SAMName;
    } else return null;
  }

  /**
   * If the user is authenticated, return the user's name and last name, otherwise return null
   * @returns the value of the function.
   */
  getFullName() {
    if (this.isAuthenticated()) {
      return this.aduser.Name + ' ' + this.aduser.LastName;
    } else return null;
  }

  /**
   * If the aduser is not null, return true, otherwise return false.
   * @returns The user object.
   */
  isAuthenticated() {
    return this.aduser != null;
  }

  /**
   * If the user is not null and the project is not null, then if the user is a client, return true if
   * the user has a permission group that includes the project name, else if the user is a development
   * team member or a quality control member, return true, else return true if the user has a
   * permission group that includes the word "Administrator"
   * @param {string} project - string = the name of the project
   * @param {AdUser} user - AdUser = this.aduser
   * @returns A boolean value.
   */
  hasPermissionForProject(project: string, user: AdUser = this.aduser) {
    if (user != null && !this.isNullOrWhitespace(project)) {
      if (this.isCurrentUserClient()) {
        return user.PermisionGroups.some((p) => p.SAMName.includes(project));
      } else if (
        this.isCurrentUserDevelopmentTeamMember() ||
        this.isCurrentUserQualityControllMember()
      ) {
        return true;
      } else {
        return user.PermisionGroups.some((p) =>
          p.GroupName.includes('Administrator')
        );
      }
    } else return false;
  }

  /**
   * If the input is undefined or null, return true. Otherwise, return true if the input contains only
   * whitespace.
   * @param {string} input - The string to check.
   * @returns a boolean value.
   */
  isNullOrWhitespace(input: string) {
    if (input === undefined || input == null) return true;
    return input.replace(/\s/g, '').length < 1;
  }

  /**
   * "If the user is a development team member, return 'developer', else if the user is a client,
   * return 'costumer', else return 'other'."
   * </code>
   * @param user - the user object that you want to check
   * @returns the value of the last return statement.
   */
  getRole(user = this.aduser) {
    if (this.isCurrentUserDevelopmentTeamMember(user)) {
      return 'developer';
    } else if (this.isCurrentUserClient(user)) {
      return 'costumer';
    } else return 'other';
  }

  /**
   * If the user's permission groups include the word 'client', return true.
   * @param user - the user object that you want to check
   * @returns A boolean value.
   */
  isCurrentUserClient(user = this.aduser) {
    return user.PermisionGroups.some((group) =>
      group.GroupName.toLowerCase().includes('client')
    );
  }
  /**
   * If the user is a member of the group 'InputForYouQCTeam', return true, otherwise return false.
   * @param user - the user object
   * @returns A boolean value.
   */
  isCurrentUserQualityControllMember(user = this.aduser) {
    return user.MembershipGroup.some(
      (group) => group.GroupName == 'InputForYouQCTeam'
    );
  }
  /**
   * If the user is a member of the InputForYouDevTeam group, return true, otherwise return false.
   * @param user - The user object that you want to check.
   * @returns A boolean value.
   */
  isCurrentUserDevelopmentTeamMember(user = this.aduser) {
    return user.MembershipGroup.some(
      (group) => group.GroupName == 'InputForYouDevTeam'
    );
  }

  /**
   * This function checks if a user is a member of the AtelierUsers group.
   * @param user - The "user" parameter is an object that represents a user.
   * @returns It will return `true` if the
   * `user` object passed as a parameter has a membership group with the name "AtelierUsers", and
   * `false` otherwise.
   */
  isAtelierUser(user = this.aduser) {
    return user.PermisionGroups.some(
      (group) => group.SAMName == 'ATELIERUsers'
    );
  }

  /**
   * If the current user is not a client, and the current user is not a quality control member, and the
   * current user is not a development team member, then the current user is a BPO.
   * @returns A boolean value.
   */
  isCurrentUserBPO() {
    return (
      !this.isCurrentUserClient() &&
      !this.isCurrentUserQualityControllMember() &&
      !this.isCurrentUserDevelopmentTeamMember()
    );
  }

  /**
   * If the user's membership group is equal to 'Groupe FOES', return true, else return false.
   * @param user - the user object
   * @returns A boolean value.
   */
  isGroupeFoesUser(user = this.aduser) {
    return user.MembershipGroup.some(
      (group) => group.GroupName == 'Groupe FOES'
    );
  }

  /**
   * It takes an Active Directory user object, filters out the permission groups that contain the word
   * "Atelier", maps the group names to the view names, and returns the view names
   * @param user - the user object
   * @returns An array of strings.
   */
  getUserViewsPermissions() {
    return this.userPermissions;
  }

  /**
   * It returns true if the user has access to the view, otherwise it returns false
   * @param {string} viewName - The name of the view you want to check if the user has access to.
   * @returns A boolean value.
   */
  userCanAccessToView(viewName: string) {
    if (
      this.isCurrentUserDevelopmentTeamMember() ||
      this.isCurrentUserQualityControllMember() ||
      this.isAtelierUser()
    )
      return true;
    else return this.userPermissions.includes(viewName);
  }
}
