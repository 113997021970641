/**
 * These are all the Atelier views used to check user permission access and navigation
 */
export enum AtelierViews {
  START = '',
  REGISTRATION = 'REGISTRATION/:searchNumber',
  RECEPTIONS = "RECEPTIONS",
  REGLETTERS = "REGLETTERS",
  BPOST = "BPOST",
  TOTALS = "TOTALS",
  PRINT = "PRINT",
  TRIAGE = "TRIAGE",
  DASHBOARD = "DASHBOARD",
  REPORTS = "REPORTS",
  DATA = "DATA/:tableName"
}
