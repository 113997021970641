<div fxLayout="column" fxFill>
  <app-header [showLogout]="false"></app-header>

  <div *ngFor="let item of auxArray; let i = index">
    <div fxLayout="row" fxLayoutAlign="center center">
      <canvas id="barcode_{{i}}" ></canvas>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-evenly center">
      <h2><strong>Project: </strong> {{ project }}</h2>
      <h2><strong>Creation date: </strong> {{ creationDate }}</h2>
      <h2><strong>Total batches: </strong> {{ totalBatches }}</h2>
      <h2><strong>Operator: </strong> {{ username }}</h2>
    </div>
    <div class="page"></div>
  </div>
</div>
