import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Project } from '../interfaces/Project.interface';
import { SelectionModalComponent } from '../selection-modal/selection-modal.component';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-reg-letter',
  templateUrl: './reg-letter.component.html',
  styleUrls: ['./reg-letter.component.scss'],
})
export class RegLetterComponent implements OnInit {
  barcodeList: Array<string> = [];
  displayedColumns = ['element', 'barcode', 'options'];
  dataSource = new MatTableDataSource<string>();
  objectID: any = null;
  projects: Array<Project> = [];
  projectsNames: any;
  areasNames: Array<string>;
  selectedProject: any = '';
  selectedArea: string = '';
  selectedClient: string;
  initialTime: number;
  endTime: number;

  projectsError = '';

  constructor(
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  async ngOnInit() {
    var projects = await this.apiService.getRegLetterProjects();

    if (projects.length == 0) {
      this.projectsError = "There aren't Reg. Letter projects";
    } else {
      this.projects = projects;
      this.projectsNames = projects.map((project) => {
        return project.name;
      });
      this.selectProject();
    }
  }

  selectArea(area: string) {
    this.selectedArea = area;
  }

  selectProject() {
    const dialogRef = this.dialog.open(SelectionModalComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: { list: this.projectsNames },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != null && result != undefined) {
        this.selectedProject = result;
        this.getAreas();
        this.initialTime = moment().valueOf();
      }
    });
  }

  getAreas(projectName: string = this.selectedProject) {
    this.areasNames = [];
    this.projects.forEach((project: Project) => {
      if (project.name == projectName) {
        if (project.area != '') this.areasNames.push(project.area);
        this.selectedClient = project.client;
      }
    });
    this.selectArea(this.areasNames[0]);
  }

  addBarcode(value: string) {
    this.barcodeList.push(value);
    this.dataSource.data = this.barcodeList;
    var input = <HTMLInputElement>document.getElementById('barcode');
    input.value = '';
    input.focus();
  }

  removeBarcode(id: number) {
    this.barcodeList.splice(id, 1);
    this.dataSource.data = this.barcodeList;
  }

  async savePicking() {
    this.endTime = moment().valueOf();

    var today = new Date().toISOString();
    var username = this.authService.getUsername();
    let totalTime = this.endTime - this.initialTime;

    var jsonOutput = {
      batchNumber: this.objectID,
      project: this.selectedProject,
      area: this.selectedArea,
      pickingDate: today,
      username: username,
      barcodeList: this.barcodeList.toString(),
      totalBarcodes: this.barcodeList.length,
      spentTime: totalTime,
    };

    var response = await this.apiService.sendRegLetter(jsonOutput);

    if (response.status == '201') {
      this.cancelButton();
      this._snackBar.open(
        this.selectedProject + ' Reg Letter created at ' + today,
        'Ok',
        {
          duration: this.apiService.snackbarDuration,
        }
      );
    } else {
      this._snackBar.open('Error ' + response.message, 'Ok');
    }
  }

  cancelButton() {
    this.router.navigate(['/']);
  }
}
