import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AgGridModule } from 'ag-grid-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartComponent } from './start/start.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './footer/footer.component';
import { ActionsComponent } from './actions/actions.component';
import { BatchComponent } from './batch/batch.component';
import { ThemeSwitchComponent } from './theme-switch/theme-switch.component';
import { PalletComponent } from './pallet/pallet.component';
import { RegLetterComponent } from './reg-letter/reg-letter.component';
import { HeaderComponent } from './header/header.component';
import { ProjectSelectionComponent } from './project-selection/project-selection.component';
import { ReceptionComponent } from './reception/reception.component';
import { SelectionModalComponent } from './selection-modal/selection-modal.component';
import { OtherRetourComponent } from './other-retour/other-retour.component';
import { DashboardModalComponent } from './dashboard-modal/dashboard-modal.component';
import { ProgressModalComponent } from './progress-modal/progress-modal.component';
import { GridTableComponent } from './grid-table/grid-table.component';
import { DeletionConfirmationComponent } from './deletion-confirmation/deletion-confirmation.component';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { PowerBiComponent } from './power-bi/power-bi.component';
import { PrintViewComponent } from './print-view/print-view.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { TableComponent } from './actions/table/table.component';
import { AutofocusDirectiveDirective } from './header/Directives/autofocus-directive.directive';
import { TriageComponent } from './triage/triage.component';
import { TokenInterceptor } from './interceptors/token.interceptor';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    FooterComponent,
    ActionsComponent,
    BatchComponent,
    ThemeSwitchComponent,
    PalletComponent,
    RegLetterComponent,
    HeaderComponent,
    ProjectSelectionComponent,
    ReceptionComponent,
    SelectionModalComponent,
    OtherRetourComponent,
    DashboardModalComponent,
    ProgressModalComponent,
    GridTableComponent,
    DeletionConfirmationComponent,
    PowerBiComponent,
    PrintViewComponent,
    TableComponent,
    AutofocusDirectiveDirective,
    TriageComponent,
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatListModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatTableModule,
    MatDialogModule,
    MatSnackBarModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AgGridModule.withComponents([]),
    FlexLayoutModule,
    MatDividerModule,
    MatExpansionModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
