<div fxLayout="column" fxLayoutGap="20px" fxFlexFill class="window">
  <app-header [showSearch]="true"></app-header>
  <div class="nav-menu" fxFlex>
    <mat-grid-list
      class="big-grid"
      [cols]="breakpoint"
      rowHeight="80px"
      gutterSize="20"
      (window:resize)="onResize($event)"
    >
      <mat-grid-tile
        class="home-button home-button mat-elevation-z3 animate__animated animate__fadeIn animate__slow"
        [colspan]="2"
        [rowspan]="2"
        (click)="navigate(Views.RECEPTIONS)"
      >
        <span class="material-icons-two-tone icon" *ngIf="userCanAccess(Views.RECEPTIONS); else lock"> local_shipping </span>
        <ng-template #lock>
          <span class="material-icons-two-tone icon"> lock </span>
        </ng-template>
        {{ "New Reception" | translate }}
      </mat-grid-tile>


      <mat-grid-tile
        class="home-button home-button mat-elevation-z3 animate__animated animate__fadeIn animate__slow"
        [colspan]="2"
        [rowspan]="2"
        (click)="navigate(Views.REGLETTERS)"
      >
        <span class="material-icons-two-tone icon" *ngIf="userCanAccess(Views.REGLETTERS); else lock"> fact_check </span>
        <ng-template #lock>
          <span class="material-icons-two-tone icon"> lock </span>
        </ng-template>
        {{ "Registered Letters" | translate }}
      </mat-grid-tile>


      <mat-grid-tile
        class="home-button home-button mat-elevation-z3 animate__animated animate__fadeIn animate__slow"
        [colspan]="2"
        [rowspan]="2"
        (click)="navigate(Views.BPOST)"
      >
        <span class="material-icons-two-tone icon" *ngIf="userCanAccess(Views.BPOST); else lock"> pending_actions </span>
        <ng-template #lock>
          <span class="material-icons-two-tone icon"> lock </span>
        </ng-template>
        {{ "Bpost projects" | translate }}
      </mat-grid-tile>

      
      <mat-grid-tile
        class="home-button home-button mat-elevation-z3 animate__animated animate__fadeIn animate__slow"
        [colspan]="2"
        [rowspan]="2"
        (click)="navigate(Views.TOTALS)"
      >
        <span class="material-icons-two-tone icon" *ngIf="userCanAccess(Views.TOTALS); else lock"> list_alt </span>
        <ng-template #lock>
          <span class="material-icons-two-tone icon"> lock </span>
        </ng-template>
        {{ "Totals" | translate }}
      </mat-grid-tile>

      <mat-grid-tile
        class="home-button home-button mat-elevation-z3 animate__animated animate__fadeIn animate__slow"
        [colspan]="2"
        [rowspan]="2"
        (click)="navigate(Views.PRINT)"
      >
        <span class="material-icons-two-tone icon" *ngIf="userCanAccess(Views.PRINT); else lock"> layers </span>
        <ng-template #lock>
          <span class="material-icons-two-tone icon"> lock </span>
        </ng-template>
        {{ "Print Batches" | translate }}
      </mat-grid-tile>

      <mat-grid-tile
        class="home-button home-button mat-elevation-z3 animate__animated animate__fadeIn animate__slow"
        [colspan]="2"
        [rowspan]="2"
        (click)="navigate(Views.TRIAGE)"
      >
        <span class="material-icons-two-tone icon" *ngIf="userCanAccess(Views.TRIAGE); else lock"> mail </span>
        <ng-template #lock>
          <span class="material-icons-two-tone icon"> lock </span>
        </ng-template>
        {{ "Triage" | translate }}
      </mat-grid-tile>
    </mat-grid-list>


    <mat-grid-list class="small-grid" cols="3" rowHeight="80px" gutterSize="20">
      <mat-grid-tile
        class="home-button mat-elevation-z3 animate__animated animate__fadeIn animate__slow"
        [colspan]="3"
        (click)="openDashboard()"
      >
        <span class="material-icons-two-tone icon" *ngIf="userCanAccess(Views.DASHBOARD); else lock"> dashboard </span>
        <ng-template #lock>
          <span class="material-icons-two-tone icon"> lock </span>
        </ng-template>
        {{ "Dashboard" | translate }}
      </mat-grid-tile>

      <mat-grid-tile
        class="home-button mat-elevation-z3 animate__animated animate__fadeIn animate__slow"
        [colspan]="3"
        (click)="navigate(Views.REPORTS)"
      >
        <span class="material-icons-two-tone icon" *ngIf="userCanAccess(Views.REPORTS); else lock"> assessment </span>
        <ng-template #lock>
          <span class="material-icons-two-tone icon"> lock </span>
        </ng-template>
        {{ "Reports" | translate }}
      </mat-grid-tile>
    </mat-grid-list>
  
  </div>
  <div class="spacer"></div>
  <app-footer></app-footer>
</div>
