import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Project } from '../interfaces/Project.interface';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-project-selection',
  templateUrl: './project-selection.component.html',
  styleUrls: ['./project-selection.component.scss'],
})
export class ProjectSelectionComponent implements OnInit {
  projects: Array<Project>;
  todayBpostReception: any;
  projectList: Array<any> = [];
  selectedReception: string = 'Bpost1';
  receptionTypes: any;
  disableAllButtons: boolean = true;

  constructor(private apiService: ApiService, private router: Router) {}

  ngOnInit() {
    forkJoin({
      BpostProjects: this.apiService.getBpostProjects(),
      TodayBpost1: this.apiService.getTodayBpost1(),
    }).subscribe(({ BpostProjects, TodayBpost1 }) => {
      if (BpostProjects.length > 0)
        this.projectList = BpostProjects.map((project) => {
          return { name: project.name, area: project.area, selected: true };
        });

      if (TodayBpost1) {
        this.disableAllButtons = false;

        if (TodayBpost1.projects.length) {
          this.projectList.forEach((project) => {
            let hasProject: boolean = TodayBpost1.projects.some(
              (defaultProject: any) =>
                defaultProject.name === project.name &&
                defaultProject.area === project.area
            );

            if (!hasProject) {
              project.selected = false;
            }
          });
        }
      }
    });
  }

  /**
   * It takes a project name and project area as parameters, finds the project in the project list, and
   * toggles the selected property of that project.
   * @param {string} projectName - string, projectArea: string
   * @param {string} projectArea - string = 'area1'
   */
  changeClass(projectName: string, projectArea: string) {
    var project = this.projectList.find((project) => {
      return project.name === projectName && project.area === projectArea;
    });

    project.selected = !project.selected;
  }

  /**
   * It takes an array of objects, filters out the ones that have a property of selected set to true,
   * then maps the remaining objects to a new array of objects with only the name and area properties.
   */
  selectBpostProjects() {
    var selectedProjects = this.projectList.filter((project) => {
      return project.selected;
    });

    var projects = selectedProjects.map((project) => {
      return { name: project.name, area: project.area };
    });

    var json = {
      data: projects,
    };

    this.apiService.updateBpost1Projects(json);
    this.cancelButton();
  }

  /**
   * This function navigates to the root of the application when the cancel button is clicked.
   */
  cancelButton() {
    this.router.navigate(['/']);
  }
}
