<div fxLayout="column" fxFill>
  <app-header></app-header>
  <iframe
    class="responsive-iframe"
    title="ATELIER  - DETAIL REPORT"
    src="https://app.powerbi.com/view?r=eyJrIjoiNDg2ZTI5OGMtNzllMy00OGNmLWE3NDUtYmQ1M2E5MTg5MDVjIiwidCI6IjBkODM5ZTI1LWZlNDctNGQ3Yi04MjEwLWNjMWNmNTk0OGM2MyIsImMiOjl9&embedImagePlaceholder=true&pageName=ReportSection910906354cd9f12e434e"
    frameborder="0"
    allowFullScreen="true"
  ></iframe>
</div>
