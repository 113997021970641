import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AtelierViews } from '../enums/AtelierViews';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() objectData: any = null;
  @Input() showSearch = false;
  @Input() showLogout = true;
  searchWord: string;
  searchHint: string = '';
  isUserAllowedToSearch = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private cookieService: CookieService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.isUserAllowedToSearch = this.authService.userCanAccessToView(
      AtelierViews.REGISTRATION.split('/')[0]
    );
  }

  async searchObject() {
    if (this.isUserAllowedToSearch) {
      if (this.searchWord == null) this.searchHint = 'Enter a valid number';
      else
        this.router.navigate([
          AtelierViews.REGISTRATION.split('/')[0],
          this.searchWord,
        ]);
    }
  }

  goBack() {
    this.router.navigate(['/']);
  }

  logOut() {
    if (this.cookieService.check('token')) {
      this.cookieService.delete('token', '/');
      var location = window.location.href;
      var logOutWindow = window.open(
        'https://login.input4you.be/Login/Logout',
        '_blank'
      );

      setTimeout(() => {
        logOutWindow!.close();
        window.location.href = location;
      }, 100);
    }
  }
}
