import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActionsComponent } from './actions/actions.component';
import { BatchComponent } from './batch/batch.component';
import { AtelierViews } from './enums/AtelierViews';
import { GridTableComponent } from './grid-table/grid-table.component';
import { UserHasPermissionsGuard } from './guards/user-has-permissions.guard';
import { OtherRetourComponent } from './other-retour/other-retour.component';
import { PalletComponent } from './pallet/pallet.component';
import { PowerBiComponent } from './power-bi/power-bi.component';
import { PrintViewComponent } from './print-view/print-view.component';
import { ProjectSelectionComponent } from './project-selection/project-selection.component';
import { ReceptionComponent } from './reception/reception.component';
import { RegLetterComponent } from './reg-letter/reg-letter.component';
import { StartComponent } from './start/start.component';
import { TriageComponent } from './triage/triage.component';

const routes: Routes = [
  { path: AtelierViews.START, component: StartComponent },
  {
    path: 'pallet',
    component: PalletComponent,
    canActivate: [UserHasPermissionsGuard],
  },
  {
    path: 'batch',
    component: BatchComponent,
    canActivate: [UserHasPermissionsGuard],
  },
  {
    path: 'box',
    component: BatchComponent,
    canActivate: [UserHasPermissionsGuard],
  },
  {
    path: AtelierViews.RECEPTIONS,
    component: ReceptionComponent,
    canActivate: [UserHasPermissionsGuard],
  },
  {
    path: AtelierViews.BPOST,
    component: ProjectSelectionComponent,
    canActivate: [UserHasPermissionsGuard],
  },
  {
    path: AtelierViews.REGLETTERS,
    component: RegLetterComponent,
    canActivate: [UserHasPermissionsGuard],
  },
  {
    path: AtelierViews.TOTALS,
    component: OtherRetourComponent,
    canActivate: [UserHasPermissionsGuard],
  },
  {
    path: AtelierViews.DATA,
    component: GridTableComponent,
    canActivate: [UserHasPermissionsGuard],
  },
  {
    path: AtelierViews.REGISTRATION,
    component: ActionsComponent,
  },
  {
    path: AtelierViews.REPORTS,
    component: PowerBiComponent,
    canActivate: [UserHasPermissionsGuard],
  },
  {
    path: AtelierViews.PRINT,
    component: PrintViewComponent,
    canActivate: [UserHasPermissionsGuard],
  },
  {
    path: AtelierViews.TRIAGE,
    component: TriageComponent,
    canActivate: [UserHasPermissionsGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
