import { Component } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './services/auth.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'atelier-web-app';
  loginSuccess = false;

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService
  ) {
    this.translate.addLangs(['en', 'nl', 'fr']);

    if (!this.cookieService.check('Language')) {
      this.cookieService.set('Language', 'en', { path: '/' });
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    } else {
      let language = this.cookieService.get('Language');
      this.translate.setDefaultLang(language);
      this.translate.use(language);
    }
  }

  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      this.loginSuccess = true;
    } else this.routerEvents();
  }

  routerEvents() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(async (event) => {
        if (event instanceof NavigationEnd) {
          if (
            !this.cookieService.check('token') ||
            !this.auth.isAuthenticated()
          ) {
            let token: string = '';
            let domain = this.getDomain();
            let urlToken = this.route.snapshot.queryParamMap.get('token') || '';

            //There is no cookie
            if (!this.cookieService.check('token')) {
              token = urlToken;
            }
            //There is cookie
            else {
              token = this.cookieService.get('token');
            }

            if (token != null && token != undefined && token != '') {
              await this.auth.authorize(token).then((data) => {
                if (data.result == 'Success') {
                  this.loginSuccess = true;

                  //Set or update cookie
                  this.setCookie(token);

                  //Remove token from url
                  this.removeToken();
                } else {
                  //Redirect to login
                  this.auth.login(domain);
                }
              });
            } else {
              //Redirect to login
              this.auth.login(domain);
            }
          }
        }
      });
  }

  setCookie(token: string) {
    //Set an expiration of 1 hour; 1h/24h = 0.04166666666
    this.cookieService.set('token', token, {
      expires: 0.04166666666,
      path: '/',
    });
  }

  removeToken() {
    if (this.cookieService.check('token')) {
      this.router.navigate([], {
        queryParams: {
          token: null,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  getDomain() {
    return window.location.href;
  }
}
