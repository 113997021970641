import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Project } from '../interfaces/Project.interface';
import { SelectionModalComponent } from '../selection-modal/selection-modal.component';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { Batch } from './Interfaces/Batch';
import { BoxRange, BoxRangeDisplay } from './Interfaces/BoxRange';

@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.scss', '../styles/common-styles.scss'],
})
export class BatchComponent implements OnInit {
  type: string;
  batchID: string;
  batchDisplayValue: string;
  creationDate: string;
  reception: boolean = false;
  printView: boolean = true;
  selectedProject: string = '';
  selectedArea: string = '';

  projectBoxRanges: Array<BoxRange> = [];
  boxRangesDisplayValues: Array<BoxRangeDisplay> = [];
  selectedBoxRangeIndex: number = -1;

  projects: Array<Project> = [];
  projectsNames: Array<string>;
  areasNames: Array<string>;
  boxRange: string;
  batchesNumber: number = 1;

  projectsError = '';

  dialogDefaultConfig: MatDialogConfig = {
    maxWidth: '100vw',
    maxHeight: '100vh',
    height: '100%',
    width: '100%',
    data: {},
  };

  constructor(
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.type = this.router.url.split('/')[1];
    this.creationDate = moment().format('YYYY-MM-DD');
  }

  async ngOnInit() {
    this.apiService.getAllProjects().subscribe((projects) => {
      if (projects.length == 0) {
        this.projectsError = 'Error fetching projects list';
      } else {
        this.projects = projects as Array<Project>;
        this.projectsNames = (projects as Array<Project>).map((project) => {
          return project.name;
        });
        this.selectProject();
      }
    });
  }

  selectProject() {
    const config = { ...this.dialogDefaultConfig };
    config.data = { list: this.projectsNames };

    const dialogRef = this.dialog.open(SelectionModalComponent, config);

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result != null && result != undefined) {
        this.selectedProject = result;
        this.getAreas();
        this.projectBoxRanges = await this.apiService.getBoxRangesForProject(
          this.selectedProject
        );
        this.createBoxRangeDisplayFormat();
      }
    });
  }

  createBoxRangeDisplayFormat() {
    this.projectBoxRanges.forEach((boxRange) => {
      let boxRangeSchema = '';

      for (let index = 0; index < boxRange.digits; index++) {
        boxRangeSchema += 'x';
      }

      let displayFormat = `${boxRange.prefix}${boxRangeSchema}`;

      this.boxRangesDisplayValues.push({
        displayValue: displayFormat,
        boxRange,
      });
    });
  }

  createBatchDisplayFormat(
    prefix: string,
    digits: number,
    batchIdValue: number
  ) {
    const batchIdString = batchIdValue.toString();
    this.batchDisplayValue = `${prefix}${batchIdString.padStart(digits, '0')}`;
  }

  updateBatchIdValue(event: MatSelectChange) {
    this.selectedBoxRangeIndex = event.value;
    const { digits, lastBox, prefix } =
      this.projectBoxRanges[this.selectedBoxRangeIndex];
    this.batchID = (lastBox + 1).toString();
    this.createBatchDisplayFormat(prefix, digits, lastBox);
  }

  selectArea(area: string) {
    this.selectedArea = area;
  }

  getAreas(projectName: string = this.selectedProject) {
    this.areasNames = [];
    this.projects.forEach((project: Project) => {
      if (project.name == projectName && project.area != '') {
        this.areasNames.push(project.area);
      }
    });
    this.selectedArea = this.areasNames[0];
  }

  async doneButton() {
    var username = this.authService.getUsername();
    let selectedBoxRange = null;

    if (this.selectedBoxRangeIndex !== -1) {
      selectedBoxRange = this.projectBoxRanges[this.selectedBoxRangeIndex];
      selectedBoxRange.lastBox += this.batchesNumber;
    }

    var batch: Batch = {
      batchNumber: '0',
      project: this.selectedProject,
      area: this.selectedArea,
      batchID: this.batchDisplayValue,
      boxRange: selectedBoxRange,
      creationDate: moment().utc().format().toString(),
      username: username,
      type: this.type,
      status: 'CREATED',
    };

    // Save new batch
    this.apiService
      .sendBatch(batch, this.batchesNumber)
      .subscribe((response) => {
        if (response.status == 201) {
          this.cancelButton();
          this._snackBar.open(
            `${this.selectedProject} ${this.type} created at ${this.creationDate}`,
            'Ok',
            {
              duration: this.apiService.snackbarDuration,
            }
          );
          this.apiService.logNewBatch(batch).subscribe();
        } else {
          this._snackBar.open('Error ' + response.body, 'Ok');
        }
      });

    if (this.printView) {
      this.router.navigate(['print'], {
        queryParams: {
          batchId: this.batchID,
          date: this.creationDate,
          totalBatches: this.batchesNumber,
          user: username,
          project: this.selectedProject,
        },
      });
    }
  }

  cancelButton() {
    this.router.navigate(['/']);
  }
}
