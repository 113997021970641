import { Component, Input, OnInit } from '@angular/core';
import { ActionsTableElement } from './interfaces/table.interface';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  displayedColumns = [
    'Process',
    'Operator',
    'StartTime',
    'EndTime',
    'TimeTaken',
  ];

  @Input() dataSource: ActionsTableElement[];

  constructor() {}

  ngOnInit(): void {}
}
