<div class="window">
  <div class="header">
    <app-header></app-header>
  </div>
  <div class="body" *ngIf="batchData; else searchError">
    <div class="body-container">
      <div class="row action-title">
        <span>
          {{ "Selected batch" | translate }}:
          {{ batchData.project + " " + batchData.batchID }}
        </span>
      </div>
      <div class="row action-title" *ngIf="nextStep.length > 0">
        <span>{{ "NEXT" | translate }}: {{ nextStep }}</span>
      </div>
      <div
        class="row action-confirmation"
        *ngIf="nextStep.length !== 0; else showCompletedMessage"
      >
        <button mat-raised-button (click)="cancelButton()" color="warn">
          {{ "Cancel" | translate }}
        </button>
        <button
          mat-raised-button
          class="accept-button"
          color="primary"
          (click)="confirmNextStatus()"
        >
          {{ "Ok" | translate }}
        </button>
      </div>
      <ng-template #showCompletedMessage>
        <div class="row action-title">
          <span>{{ "The batch is scanned" | translate }}</span>
        </div>
      </ng-template>

      <div class="row action-buttons">
        <mat-selection-list
          [multiple]="false"
          class="inline-list"
          appaerance="outline"
        >
          <mat-list-option disabled (click)="deleteBatch()">
            <span class="material-icons-two-tone icon">change_circle</span>
            {{ "Change project" | translate }} {{ batchData.type }}
          </mat-list-option>
          <mat-list-option (click)="deleteBatch()">
            <span class="material-icons-two-tone icon">delete</span>
            {{ "Delete" | translate }} {{ batchData.type }}
          </mat-list-option>
        </mat-selection-list>
      </div>

      <div class="row">
        <app-table [dataSource]="tableInfo"></app-table>
      </div>
    </div>
  </div>

  <ng-template #searchError>
    <div class="search-error">
      {{ searchMessage }}
    </div>
  </ng-template>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</div>
