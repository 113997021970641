import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SelectionModalComponent } from '../selection-modal/selection-modal.component';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import * as moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-other-retour',
  templateUrl: './other-retour.component.html',
  styleUrls: ['../styles/common-styles.scss', './other-retour.component.scss'],
})
export class OtherRetourComponent implements OnInit {
  otherTypes: any = [];
  otherTypesNames: Array<string> = [];
  numPositions: string;
  otherError = '';

  othersForm = new FormGroup({
    type: new FormControl('', [Validators.required]),
    numberTotals: new FormControl('', [Validators.required]),
    time: new FormControl('', [Validators.required, Validators.min(0)]),
    creationDate: new FormControl(moment().format('YYYY-MM-DDTHH:mm'), [
      Validators.required,
    ]),
    username: new FormControl(''),
  });

  constructor(
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.apiService.getOtherTypes().subscribe((otherTypes) => {
      this.otherTypes = otherTypes;
      this.otherTypesNames = this.otherTypes.map((project: any) => {
        return project.name;
      });
      this.selectProject();
    });
  }

  getPositions() {
    var other = this.otherTypes.find((other: any) => {
      return this.othersForm.get('type') == other.name;
    });

    if (other) {
      this.othersForm
        .get('numberTotals')
        .addValidators(Validators.maxLength(other.positions));

      this.numPositions = other.positions;
    }
  }

  selectProject() {
    console.log("lista length: ", this.otherTypesNames.length)

    const dialogRef = this.dialog.open(SelectionModalComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: { list: this.otherTypesNames.sort() },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != null && result != undefined) {
        this.othersForm.get('type').setValue(result);
        this.getPositions();
      }
    });
  }

  doneButton() {
    this.othersForm.get('username').setValue(this.authService.getUsername());

    const currentDate = this.othersForm.get('creationDate').value;
    const utcDate = moment(currentDate).utc().format();
    this.othersForm.get('creationDate').setValue(utcDate);

    this.apiService.sendOthers(this.othersForm.value).subscribe({
      next: () => {
        this.cancelButton();
        this._snackBar.open(
          `${this.othersForm.get('type').value} 
          created at ${currentDate}`,
          'Ok',
          {
            duration: this.apiService.snackbarDuration,
          }
        );
      },
      error: () => {
        this._snackBar.open('Error saving new Other', 'Ok', {
          duration: this.apiService.snackbarDuration,
        });
      },
    });
  }

  cancelButton() {
    this.router.navigate(['/']);
  }
}
